import * as React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import { FileEntity } from '../../../types/entities/file';
import SortableItem from './sortable-item';
import UploadButton from './upload-button';
import { UPLOADER_DEFAULT_MAX_FILE_SIZE } from '../../../utils/config';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/store';
import { ProjectSectionRangeSize } from '../../../types/entities/project-section';

interface SortableListEntity {
    files: FileEntity[];
    dpi: number;
    sectionHash: string;
    extensions: string;
    maxSize: string;
    size: string | null;
    dimensions: string | null;
    range: ProjectSectionRangeSize | null;
    enableChunks: boolean;
    disabled: boolean;
    explainImage: string | undefined;
}

export const getFilesContainerId = (sectionHash: string) => `section_files_${sectionHash}`;

const SortableList = SortableContainer<SortableListEntity>((props: SortableListEntity) => {
    const {
        files,
        sectionHash,
        extensions,
        maxSize,
        size,
        dimensions,
        enableChunks,
        disabled,
        explainImage,
        dpi,
        range,
    } = props;

    const limitMax = useSelector((state: RootState) => state.config[sectionHash].files.limit.max);
    return (
        <div
            className={`files-container ${files.length === 0 ? 'button-full' : 'with-files'}`}
            id={getFilesContainerId(sectionHash)}
        >
            {files.map((file, i) => {
                return (
                    <SortableItem
                        index={i}
                        file={file}
                        filesCount={files.length}
                        key={`sort_key_${file.id}`}
                        disabled={disabled}
                    />
                );
            })}

            {files.length < limitMax ? (
                <UploadButton
                    explainImage={explainImage}
                    filesCountInSection={files.length}
                    sectionHash={sectionHash}
                    extensions={extensions}
                    maxSize={maxSize || UPLOADER_DEFAULT_MAX_FILE_SIZE}
                    size={size}
                    dpi={dpi}
                    dimensions={dimensions}
                    range={range}
                    enableChunks={enableChunks}
                />
            ) : null}
        </div>
    );
});

export default SortableList;
