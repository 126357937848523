import { Size, ProjectSectionSize, ProjectSectionRangeSize } from '../types/entities/project-section';
import { DEFAULT_DPI } from './config';
import { convertMmToPx } from './helpers';

export const isProjectSectionSize = (size: Size): size is ProjectSectionSize => {
    return 'width' in size && 'height' in size;
};

export const isProjectSectionRangeSize = (size: Size): size is ProjectSectionRangeSize => {
    return 'range' in size && 'width' in size.range && 'height' in size.range;
};

export const formatProjectSize = (size: Size): string | null => {
    return isProjectSectionSize(size) ? `${size.width}x${size.height}` : null;
};

export const formatProjectDimensions = (size: Size): string | null => {
    return isProjectSectionSize(size)
        ? `${convertMmToPx(size.width, DEFAULT_DPI)}x${convertMmToPx(size.height, DEFAULT_DPI)}`
        : null;
};
export const getProjectRangeSize = (size: Size): ProjectSectionRangeSize | null => {
    return isProjectSectionRangeSize(size) ? size : null;
};
