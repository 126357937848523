import * as React from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash-es/isEmpty';
import { SortEnd } from 'react-sortable-hoc';
import { Tooltip } from 'react-tooltip';

import { ProjectSectionEntity } from '../../../types/entities/project-section';
import { UPLOADER_DEFAULT_MAX_FILE_SIZE, DEFAULT_DPI } from '../../../utils/config';
import { FileEntity } from '../../../types/entities/file';
import SortableList from './sortable-list';
import UploadList from '../../upload-list/components/upload-list';
import * as FilesSortingActions from '../../files/actions/sorting';
import { saveSorting, calcPositions } from '../../files/services/sorting';
import * as SelectedFiles from '../../../reducers/selected-files-reducer';
import { sortFiles, sortFilesByName } from '../../../utils/helpers';
import { validateSection } from '../services/validation';
import FileModalDelete from '../../files/components/modal-delete';
import { WaitingToDeleteContext } from './waiting-to-delete-context';
import store, { RootState } from '../../../utils/store';
import { __ } from '../../../utils/i18n';
import { validateFile } from '../../files/services/validation';
import * as FilesValidationActions from '../../files/actions/validation';
import IconSort from '../../../components/svg-icons/sort';
import IconTrash from '../../../components/svg-icons/trash';

import '../assets/style.scss';
import { useState } from 'react';
import { saveSectionData } from '../../../reducers/data-reducer';
import CheckAllIcon from '../../../components/svg-icons/check-all';
import { formatProjectDimensions, formatProjectSize, getProjectRangeSize } from '../../../utils/project-section';

interface Props {
    sectionHash: string;
    index: number;
}

interface PropsConnected extends Props {
    sectionConfig: ProjectSectionEntity;
    sectionData: FileEntity[];
    selectedFiles: number[];
    isEmptyUploadList: boolean;
}

interface State {
    showDeleteManyModal: boolean;
    waitingToDelete: number[];
    oldSectionData: FileEntity[] | null;
    sorted: boolean;
}

const Section = (props: Props & PropsConnected) => {
    const [state, setState] = useState<State>({
        showDeleteManyModal: false,
        waitingToDelete: [],
        oldSectionData: null,
        sorted: false,
    });

    const { index, sectionHash, isEmptyUploadList, sectionConfig, sectionData, selectedFiles } = props;
    const { extensions, maxFileSize, size } = sectionConfig.files;
    const { min, max } = sectionConfig.files.limit;

    React.useEffect(() => {
        if (JSON.stringify([...sectionData].sort(sortFiles)) != JSON.stringify(sectionData)) {
            store.dispatch(saveSectionData([...sectionData].sort(sortFiles), sectionHash));
        }
    }, [sectionData]);

    const renderFiles = () => {
        const sortedFiles = [...sectionData].sort(sortFiles);

        return (
            <WaitingToDeleteContext.Provider
                value={{
                    idsToDelete: state.waitingToDelete,
                    afterDelete: (file) => {
                        setState({
                            ...state,
                            waitingToDelete: state.waitingToDelete.filter((item) => item !== file.id),
                        });
                        store.dispatch(SelectedFiles.unselect([file]));
                    },
                }}
            >
                <SortableList
                    disabled={!sectionConfig.dragAndDrop}
                    helperClass="sort-in-progress"
                    distance={50}
                    axis="xy"
                    sectionHash={sectionHash}
                    files={sortedFiles}
                    extensions={extensions}
                    maxSize={maxFileSize || UPLOADER_DEFAULT_MAX_FILE_SIZE}
                    dpi={DEFAULT_DPI}
                    size={formatProjectSize(size)}
                    dimensions={formatProjectDimensions(size)}
                    range={getProjectRangeSize(size)}
                    enableChunks={sectionConfig.enableChunks}
                    explainImage={sectionConfig.explainImage}
                    onSortEnd={({ oldIndex, newIndex }: SortEnd) => {
                        if (oldIndex !== newIndex) {
                            const movedItem = sortedFiles.splice(oldIndex, 1);
                            sortedFiles.splice(newIndex, 0, movedItem[0]);

                            store.dispatch(FilesSortingActions.save(sectionHash, calcPositions(sortedFiles)));
                            saveSorting(sectionHash);
                        }
                    }}
                />
            </WaitingToDeleteContext.Provider>
        );
    };

    const handleSort = () => {
        if (!state.oldSectionData) {
            setState({ ...state, oldSectionData: sectionData });
        }

        const sortedByName = [...sectionData].sort(sortFilesByName);

        if (state.sorted && state.oldSectionData) {
            setState({ ...state, sorted: !state.sorted });
            store.dispatch(FilesSortingActions.save(sectionHash, calcPositions(state.oldSectionData)));
            saveSorting(sectionHash);
        } else {
            setState({ ...state, sorted: !state.sorted });
            store.dispatch(FilesSortingActions.save(sectionHash, calcPositions(sortedByName)));
            saveSorting(sectionHash);
        }
    };
    const handleSelect = () => {
        if (selectedFiles.length === 0) {
            store.dispatch(SelectedFiles.select(sectionData));
        } else {
            store.dispatch(SelectedFiles.unselectAll(sectionHash));
        }
    };
    const showDeleteManyModal = () => {
        setState({ ...state, showDeleteManyModal: true });
    };
    const hideDeleteManyModal = () => {
        setState({ ...state, showDeleteManyModal: false });
    };

    const removeManyFiles = () => {
        setState({ ...state, showDeleteManyModal: false, waitingToDelete: selectedFiles.map((id) => Number(id)) });
    };
    React.useEffect(() => {
        if (sectionData.length > 0) {
            sectionData.forEach((file) => {
                const error = validateFile(file);
                if (error !== true) {
                    store.dispatch(FilesValidationActions.addErrors(file, [error as string]));
                }
            });

            validateSection(sectionHash);
        }
    }, []);

    return (
        <div className="app-section">
            <div className="header">
                <div className="title-container">
                    <div className="title">
                        {index}. {__(sectionConfig.title)}
                    </div>
                    {/* {sectionData.length > 0 ? <ValidationInfo sectionHash={sectionHash} /> : null} */}
                </div>
                <div className="files-counter">
                    {__('Ilość')}
                    <span>
                        {sectionData.length}/{`${min === max ? max : `${min}-${max}`}`}
                    </span>
                </div>
                <div className="files-actions">
                    <div className="checkbox-all">
                        <button
                            className="button icon"
                            disabled={sectionData.length === 0}
                            onClick={handleSelect}
                            data-tooltip-content={__('Zaznacz / Odznacz wszystkie pliki')}
                            data-tooltip-id="section-header-tooltip"
                        >
                            {/* {selectedFiles.length === sectionData.length ? <IconCheckboxSelected /> : <IconCheckbox />} */}
                            <CheckAllIcon /> {__('Zaznacz wszystko')}
                        </button>
                    </div>
                    <div className="sort-by-name">
                        <button
                            className="button icon"
                            onClick={handleSort}
                            data-tooltip-content={__('Posortuj po nazwie')}
                            data-tooltip-id="section-header-tooltip"
                        >
                            <IconSort /> {__('Sortuj po nazwie')}
                        </button>
                    </div>

                    <div className="delete-checked">
                        <button
                            className="button icon"
                            disabled={selectedFiles.length === 0}
                            onClick={showDeleteManyModal}
                            data-tooltip-content={__('Usuń zaznaczone')}
                            data-tooltip-id="section-header-tooltip"
                        >
                            <IconTrash active /> {__('Usuń zaznaczone')}
                        </button>
                    </div>
                </div>
            </div>

            <div className={`section-content ${!isEmptyUploadList ? 'with-upload-list' : ''}`}>
                {/* {sectionConfig.dragAndDrop && sectionData.length > 0 ? (
                    <div className="sorting-warning">
                        <IconWarning />
                        <span className="text">{__('Ważne! Upewnij się, że kolejność plików jest poprawna.')}</span>
                    </div>
                ) : null} */}

                {renderFiles()}
                {!isEmptyUploadList ? <UploadList sectionHash={sectionHash} /> : null}
            </div>

            {state.showDeleteManyModal ? (
                <FileModalDelete
                    onClose={hideDeleteManyModal}
                    removeHandler={removeManyFiles}
                    multiple={true}
                    filesCount={selectedFiles.length}
                />
            ) : null}
            <Tooltip place="bottom" id="section-header-tooltip" />
        </div>
    );
};

const mapStateToProps = (state: RootState, props: Props) => {
    const { sectionHash } = props;
    return {
        sectionConfig: state.config[sectionHash],
        sectionData: state.data[sectionHash],
        selectedFiles: state.selectedFiles[sectionHash],
        isEmptyUploadList: isEmpty(state.uploadList[sectionHash]),
    };
};

export default connect(mapStateToProps)(Section);
